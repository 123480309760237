import React, { useState, useEffect }        from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';

// import constants
import { AUTH_URL } from '../constants';

// import helpers
import { postData } from '../helpers';

// import components
import ResetPasswordRequestToken from '../components/elements/ResetPassword/ResetPasswordRequestToken';
import ResetPasswordForm         from '../components/elements/ResetPassword/ResetPasswordForm';
import Seo                       from '../components/common/Seo';

const ResetPasswordPage = () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          reset_password {
            title
          }
        }
      }
    }
  `);

  // define the default component state
  const [tokenExpired, setTokenExpired] = useState( false );
  const [texts, setTexts] = useState( { resetTitle: '' } );
  const { resetTitle } = texts;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { reset_password } = node;

      setTexts( t => ({
        ...t,
        resetTitle: reset_password[1].title
      }));
    });
  }, [data] );

  /**
   * Sending the reset token
   */
  useEffect( () => {
    const getParams = new URLSearchParams( window.location.search );
    const token     = getParams.get( 'token' );
    const uidb64    = getParams.get( 'uidb64' );

    if (
      ( token === null || token === undefined ) &&
      ( uidb64 === null || uidb64 === undefined )
    ) {
      navigate( '/en/' );
    } else {
      const data = {
        uidb64,
        token
      };

      postData( AUTH_URL + 'password-reset/', 'POST', data, false )
        .then( data => {
          const response = data[0];
          const { status } = response;

          // if the token is invalid
          if ( status === 401 ) {
            setTokenExpired( true );
          }

          // if the tokens are invalid or undefined
          if ( status === 500 ) {
            navigate( '/en/' );
          }
        });
    }
  }, [] );

  return (
    <>
      <Seo title={ resetTitle } />
      { tokenExpired ? <ResetPasswordRequestToken currentLang="en" /> : <ResetPasswordForm currentLang="en" /> }
    </>
  )
};

export default ResetPasswordPage;